import React, { FC } from "react"
import Layout from "../../layout/layout"
import style from "./applications.module.scss"
import SEO from "../../components/utilities/seo/SEO"
import { graphql } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"

const ApplicationsPage = ({ data }: any) => {
  const { aerosolImg, cubeImg, gelImg } = data

  return (
    <Layout title="Applications" darkHeader className={style.applications}>
      <SEO
        lang="en"
        title="Applications of the OCD range | OCD"
        description="OCD DEO MAX can be used anywhere there is a bad smell. Here are some examples:"
        image="https://ocdonline.co.uk/images/ocd/OCD.png"
        keywords="Applications, OCD, uses"
        meta
      />
      <section className={style.intro}>
        <p>
          OCD DEO MAX can be used anywhere there is a bad smell. Here are some
          examples:
        </p>
      </section>
      <Application
        img={{
          url: cubeImg.childImageSharp.fluid,
          alt: "OCD Fresh Linen Odour Eliminator Cubes",
        }}
        title="The Office Toilet"
        body="Throw down an OCD DEO MAX cube and you won’t have to blame Gemma from accounts ever again."
      />
      <Application
        img={{
          url: aerosolImg.childImageSharp.fluid,
          alt: "OCD Bubblegum Odour Eliminator Aerosol",
        }}
        title="The Hallway"
        body="Keep the smell of feet out of your hallway with a quick spray of OCD Aerosol."
      />
      <Application
        img={{
          url: gelImg.childImageSharp.fluid,
          alt: "OCD Original Odour Eliminator Gel",
        }}
        title="The Dog"
        body="Leave a tub of gel in the car after a walk in the rain to clear that wet dog smell for the next time you get in."
      />
    </Layout>
  )
}

export default ApplicationsPage

interface applicationProps {
  img?: {
    url: FluidObject
    alt: string
  }
  title: string
  body: string
}

const Application: FC<applicationProps> = ({ img, title, body }) => {
  return (
    <section className={style.application}>
      <div className={style.imgWrapper}>
        {img && <GatsbyImage fluid={img.url} alt={img.alt} />}
      </div>
      <div className={style.content}>
        <h2 className={style.title}>{title}</h2>
        <p className={style.body}>{body}</p>
      </div>
    </section>
  )
}

// Get images for applications page
export const query = graphql`
  {
    aerosolImg: file(relativePath: { eq: "ocd-spray-applications.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cubeImg: file(relativePath: { eq: "ocd-orange-cube-applications.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gelImg: file(relativePath: { eq: "applications-ocd-for-dog.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
